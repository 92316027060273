import React, {useCallback, useState} from "react";
import moment from "moment";
import {Form, Input, Button, Descriptions, Skeleton, Switch, DatePicker} from "antd";
import AdminLayout from "../_layout/AdminLayout";
import {useCommonSettings, updateInfoStripSetting} from "../../api/settings";
import {
  downloadOpenProcurementsLogCSV,
  downloadSubscriptionCSV,
  downloadOpenProcurementsLogCSVFreshSales,
} from "../../api/organizations";
import {showError, showSuccess} from "helpers/notifications.helper";

const CommonSettingsPage = () => {
  const [isLoadingFreshSales, setIsLoadingFreshSales] = useState(false);
  const {RangePicker} = DatePicker;
  const [downloadCSVForm] = Form.useForm();
  const [downloadSubscriptionsCSVForm] = Form.useForm();
  const [updateInfoStripForm] = Form.useForm();
  const [, settingsInfo, loading, reloadSettings] = useCommonSettings();

  const disabledDate = (current) => Date.parse(current?.format()) > Date.now();

  const onUpdateInfoStrip = useCallback((values) => {
    updateInfoStripSetting(values)
      .then(reloadSettings)
      .finally(() => showSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadCSV = (obj, keyFunction) =>
    keyFunction(obj).then((res) => {
      const blob = new Blob([res.data], {type: "text/csv"});
      const csvURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", `${obj.fromDate}-${obj.toDate}.csv`);
      tempLink.click();
    });

  const onDownloadCSV = useCallback((obj, key) => {
    const keyFunction = {
      downloadOpenProcurementsLogCSV: downloadOpenProcurementsLogCSV,
      downloadSubscriptionCSV: downloadSubscriptionCSV,
    };
    if (!obj.dateRange) return showError("Select dates!");
    downloadCSV(
      {
        fromDate: obj.dateRange?.[0].format("YYYY-MM-DD"),
        toDate: obj.dateRange?.[1].format("YYYY-MM-DD"),
      },
      keyFunction[key]
    ).then(() => {
      showSuccess("Successfully downloaded!");
      key === "downloadOpenProcurementsLogCSV" && downloadCSVForm.resetFields();
      key === "downloadSubscriptionCSV" && downloadSubscriptionsCSVForm.resetFields();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadCSVFreshSales = useCallback(() => {
    setIsLoadingFreshSales(true);
    downloadOpenProcurementsLogCSVFreshSales()
      .then((res) => {
        const blob = new Blob([res.data], {type: "text/csv"});
        const csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "freshsales-companies.csv");
        tempLink.click();
        showSuccess("CSV downloaded successfully for Freshsales!");
      })
      .catch(() => showError("Failed to download CSV for Freshsales."))
      .finally(() => {
        setIsLoadingFreshSales(false);
      });
  }, []);

  return (
    <AdminLayout>
      <div className="content-box">
        {loading && <Skeleton active />}
        {!loading && settingsInfo && (
          <Descriptions
            title={<h1>Settings home page</h1>}
            bordered
            column={1}
            labelStyle={{width: 200}}
          >
            <Descriptions.Item label="Info strip">
              <Form
                form={updateInfoStripForm}
                layout="inline"
                initialValues={{
                  text: settingsInfo?.infoStrip?.text,
                  active: settingsInfo?.infoStrip?.active,
                }}
                onFinish={onUpdateInfoStrip}
              >
                <Form.Item name="text" style={{flexGrow: 2}}>
                  <Input placeholder="Info strip text" />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked">
                  <Switch checkedChildren="On" unCheckedChildren="Off" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Form>
            </Descriptions.Item>
            <Descriptions.Item label="Open procurement matches">
              <div className="df-row-center">
                <h4 className="mr-6 mt-1">
                  Export open procurements matches log into CSV -&gt;
                </h4>
                <Form
                  form={downloadCSVForm}
                  layout="inline"
                  onFinish={(obj) => onDownloadCSV(obj, "downloadOpenProcurementsLogCSV")}
                  initialValues={{dateRange: [moment().subtract(1, "years"), moment()]}}
                >
                  <Form.Item name="dateRange">
                    <RangePicker
                      format="DD/MM/YYYY"
                      defaultValue={[moment().subtract(1, "years"), moment()]}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Download
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Export for freshsales">
              <div className="df-row-center">
                <h4 className="mr-6 mt-1">Export companies for freshsales -&gt;</h4>
                <Button
                  type="primary"
                  onClick={() => onDownloadCSVFreshSales()}
                  loading={isLoadingFreshSales}
                  disabled={isLoadingFreshSales}
                >
                  Export
                </Button>
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Export subscriptions">
              <div className="df-row-center">
                <h4 className="mr-6 mt-1">Export subscriptions info into CSV -&gt;</h4>
                <Form
                  form={downloadSubscriptionsCSVForm}
                  layout="inline"
                  onFinish={(obj) => onDownloadCSV(obj, "downloadSubscriptionCSV")}
                  initialValues={{dateRange: [undefined, moment()]}}
                >
                  <Form.Item name="dateRange">
                    <RangePicker
                      format="DD/MM/YYYY"
                      defaultValue={[undefined, moment()]}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Download
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
    </AdminLayout>
  );
};

export default CommonSettingsPage;
